import React, { Component } from 'react';
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  Linking,
} from 'react-native';
import { connect } from 'react-redux';

import * as R from 'ramda';
import moment from 'moment';
import { withFormik, Field } from 'formik';
import { I18n } from 'react-redux-i18n';

import { Ionicons as Icon } from '@expo/vector-icons';

import { Grid, Row, Col } from '../Components/Grid';

// import ScanID from './ScanID';
// import ContactInfo from './ContactInfo';
// import UploadTaxReturn from './Income/UploadTaxReturn';
// import RetrieveTaxReturn from './Income/RetrieveTaxReturn';
// import IRS4506T from './Income/IRS4506T';
// import IRS4506TSignature from './Income/IRS4506TSignature';
// import OtherIncome from './Income/OtherIncome';
// import NoIncomeAffidavit from './Income/NoIncomeAffidavit';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupRadio from '../Components/FormGroupRadio';

import ApplicationActions, {
  checkRentalProgram,
} from '../Redux/ApplicationRedux';
import DocumentActions from '../Redux/DocumentRedux';

// import TopNav from '../Components/TopNav';
import FullButton from '../Components/FullButton';

import {
  getBase,
  getPersonId,
  hasDocument,
  isNumeric,
  isRequired,
} from '../Lib/Utils';

// Styles
import styles from './Styles/ScreenStyles';
import { Fonts, Colors, Metrics } from '../Themes';

import {
  RENTAL_PROGRAM_CODE,
  MOBILE_HOME_CODE,
  YES_OPTION,
  TRUE_OPTION,
} from '../Config/ApplicationConfig';

import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';
import DATA_STRUCTURE from '../Config/DataStructureConfig';
import { setApplication } from '../Redux/PersistRedux';
import Footer from '../Components/Footer';

const BASE = DATA_STRUCTURE.lien_holders;
const BASE_DAMAGED_ADDRESS = DATA_STRUCTURE.damagedAddress;

const DOCUMENT_TYPE = DOCUMENT_TYPES.lienDocument.code;

export const TemporaryRelocationScreenComplete = (application, documents) => {
  return application.canApplicantRelocateCode !== undefined;
};

class TemporaryRelocationScreen extends Component {
  state = {
    currentScreen: null,
    editingIndex: null,
  };

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      data,
      errorText,
      fetching,
      application,
      initilized,
      onComplete,
      currentForm,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history,
    } = nextProps;

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        history.push('/EnvironmentalInformationScreen');
      }
    }
  }

  render() {
    const {
      application,
      initilized,
      fetching,
      history,
      options,
      submitForm,
      values,
    } = this.props;

    const { editingIndex, currentScreen, errorText } = this.state;

    // console.log('values', JSON.stringify(values, null, 2));

    const disabled = false;

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    const moreInfoText = I18n.t('TemporaryRelocation.subtitle');

    const url = 'https://scstormrecovery.com/';

    return (
      <View style={styles.mainContainer}>
        <ScrollView
          ref={(scroll) => {
            this._scroll = scroll;
          }}
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id={`TemporaryRelocation.title`}
                  style={Fonts.style.title}
                />
                <View>
                  <Text style={Fonts.style.subtitle}>
                    {moreInfoText}
                    <Text style={{ fontFamily: Fonts.type.bold }}>
                      844-410-8560
                    </Text>
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    Linking.canOpenURL(url).then((supported) => {
                      if (supported) {
                        Linking.openURL(url);
                      } else {
                        console.log("Don't know how to open URI: " + url);
                      }
                    });
                  }}
                >
                  <Text
                    style={{
                      ...Fonts.style.subtitle,
                      color: 'blue',
                      textDecorationLine: 'underline',
                      textAlign: 'center',
                    }}
                  >
                    {url}
                  </Text>
                </TouchableOpacity>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {errorView}
                <FormGroupRadio
                  field="canApplicantRelocateCode"
                  labelId={`TemporaryRelocation.canApplicantRelocateCode`}
                  optionsKey="yes_no"
                  required
                  validate={isRequired}
                  disabled={disabled}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field="hasApplicantMadePlansToRelocateCode"
                  labelId={`TemporaryRelocation.hasApplicantMadePlansToRelocateCode`}
                  optionsKey="yes_no"
                  required
                  validate={isRequired}
                  disabled={disabled}
                />
              </Col>
            </Row> */}

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  onPress={submitForm}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </ScrollView>
      </View>
    );
  }
}

TemporaryRelocationScreen = withFormik({
  mapPropsToValues: (props) => {
    const initialApplication = JSON.parse(JSON.stringify(props.application));
    // console.log(
    //   'mapPropsToValues',
    //   JSON.stringify(initialApplication, null, 2)
    // );
    return initialApplication;
  },
  isInitialValid: (props) =>
    props.application[BASE] && props.application[BASE].length > 0,
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { updateApplication } = props;
    console.log('updatingApplication', values);
    updateApplication(values);
    setStatus('updatingApplication');
  },
})(TemporaryRelocationScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { options, application, account, token, tokenId },
  document,
}) => ({
  options,
  account,
  token,
  tokenId,
  errorText: error,
  fetching,
  data,
  initilized,
  application,
  documents: document.documents,
  documentsError: document.error,
  documentsFetching: document.fetching,
});

const mapDispatchToProps = (dispatch) => ({
  loadDocuments: (docType) =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  // deleteHouseholdMember: memberId =>
  //   dispatch(ApplicationActions.ApplicationHouseholdMemberDelete(memberId)),
  setApplication: (application) =>
    dispatch(ApplicationActions.ApplicationSuccess(application)),
  updateApplication: (application) =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
  deleteRecord: (memberId, key) =>
    dispatch(ApplicationActions.ApplicationDeleteRecord(BASE, memberId, key)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemporaryRelocationScreen);
