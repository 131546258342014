// eslint-disable-next-line import/no-unresolved
// import { DB_ENV } from '@env';
import Constants from 'expo-constants';

const releaseChannel = Constants.manifest.releaseChannel || '';
export const PRODUCTION =
  releaseChannel.indexOf('prod') !== -1 ||
  Constants.manifest.extra?.db_env?.toUpperCase() === 'PROD';

console.log('db_env', releaseChannel, Constants.manifest);
export const API_VERSION = 2;

export const API_PREFIX = 'SC';

// Default in Code allow env variables to change
let api_env = 'QA'; // DEV | QA | PROD

// Production Release Channel is always PROD DB
const DB_ENV = PRODUCTION ? 'prod' : Constants.manifest.extra.db_env;

// Allow .env to override (for prod)
switch (DB_ENV) {
  case 'PROD':
  case 'prod':
    api_env = 'PROD';
    break;
  case 'DEV':
  case 'dev':
    api_env = 'DEV';
    break;
  case 'QA':
  case 'qa':
    api_env = 'QA';
    break;
  default:
  // do nothing;
}
// Force Prod DB if production
export const API_ENVIROMENT = PRODUCTION ? 'PROD' : api_env;
console.log('DB_ENV', DB_ENV);
console.log('API_ENVIROMENT', API_ENVIROMENT);

// TODO: TBD
// export const GOOGLE_ANALYTIC_CODE = 'UA-138239120-1';
export const PRIVACY_POLICY_LINK = 'https://onbase.hornellp.com/privacy';

export const GROUP = 'South Carolina DRO - Hurricane Florence';

export const PROGRAM_CODE = '10';
export const RENTAL_PROGRAM_CODE = '11';

export const YES_OPTION = '2';
export const NO_OPTION = '1';
export const UNKNOWN_OPTION = '3';

export const TRUE_OPTION = '1';
export const FALSE_OPTION = '0';

export const MOBILE_HOME_CODE = '24';

// Address Types  UNUSED USVI
export const ADDRESS_TYPE_PO_BOX = '1';
export const ADDRESS_TYPE_RURAL_ROUTE = '2';
export const ADDRESS_TYPE_HIGHWAY_CONTRACT = '3';
export const ADDRESS_TYPE_RESIDENTIAL = '4';
export const ADDRESS_TYPE_APARTMENT = '5';

export const DEFAULT_STATE_CODE = '40'; // South Carolina

export const REFERRAL_VOAD = '34';
export const REFERRAL_LTRG = '29';
export const REFERRAL_OTHER = '31';

export const JOINT_TAX_RETURN = '2';

export const ORGIN_CODE_WEB = '16';
export const ORGIN_CODE_MOBILE = '17';

export const DEFAULT_LANGUAGE = 'en';
// export const DEFAULT_LANGUAGE = 'es';

export const LANGUAGE_CODE_EN = '1';
export const LANGUAGE_CODE_ES = '2';

export const VENDOR_NAME = 'Contravent';

export const STATUS_IN_PROGRESS = 'In Progress';
export const STATUS_SUBMITTED = 'Submitted';

export const STATUS_SUBMITTED_PENDING_DOCUSIGN = 'Submitted Pending Docusign';

const ALL_API_CREDENTIALS = {
  PROD: {
    API_BASE_URL: 'https://api-canopy.hornellp.com/ws/rest/GS/Intake',
    AUTHORIZATION:
      'Z3NpbnRha2VAaG9ybmVsbHAtQkY2WUJTOmFjMTgyZDczLTA1ZmItNDg2NC05Y2RmLWY3MjMwMzY1OGI3MQ==',
    WEB_BASE_URL: 'https://horne-fl.psg-labs.com/',
  },
  QA: {
    API_BASE_URL: 'https://api-canopy-qa.hornellp.com/ws/rest/GS/Intake',
    AUTHORIZATION:
      'Z3NpbnRha2UtcWFAaG9ybmVsbHAtQkY2WUJTOjg4NmJkOTgwLTcyNDctNGQ1ZS05ZmE1LTQzMGJmNzE0NjYzOQ==',
    WEB_BASE_URL: 'https://horne-fl-qa.psg-labs.com/',
  },
  DEV: {
    API_BASE_URL: 'https://api-canopy-dev.hornellp.com/ws/rest/GS/Intake',
    AUTHORIZATION:
      'Z3NpbnRha2UtZGV2QGhvcm5lbGxwLUJGNllCUzpiODg4MjA3Zi1mMDM5LTRiMjYtOGM0MS1kNzk4MDVhZmYxMmE=',
    WEB_BASE_URL: 'https://horne-fl-dev.psg-labs.com/',
  },
};

export const API_CREDENTIALS = ALL_API_CREDENTIALS[API_ENVIROMENT];

export const IMAGE_RESIZE_MAX = 3000;
export const IMAGE_RESIZE_QUALITY = 60;
