import React, { Component } from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';

import * as R from 'ramda';
import moment from 'moment';
import { withFormik, Field } from 'formik';
import { I18n } from 'react-redux-i18n';

import { Ionicons as Icon } from '@expo/vector-icons';

import { Grid, Row, Col } from '../Components/Grid';

// import ScanID from './ScanID';
// import ContactInfo from './ContactInfo';
// import UploadTaxReturn from './Income/UploadTaxReturn';
// import RetrieveTaxReturn from './Income/RetrieveTaxReturn';
// import IRS4506T from './Income/IRS4506T';
// import IRS4506TSignature from './Income/IRS4506TSignature';
// import OtherIncome from './Income/OtherIncome';
// import NoIncomeAffidavit from './Income/NoIncomeAffidavit';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupRadio from '../Components/FormGroupRadio';

import ApplicationActions, {
  checkRentalProgram,
} from '../Redux/ApplicationRedux';
import DocumentActions from '../Redux/DocumentRedux';

// import TopNav from '../Components/TopNav';
import FullButton from '../Components/FullButton';

import {
  getBase,
  getPersonId,
  hasDocument,
  isNumeric,
  isRequired,
} from '../Lib/Utils';

// Styles
import styles from './Styles/ScreenStyles';
import { Fonts, Colors, Metrics } from '../Themes';

import {
  RENTAL_PROGRAM_CODE,
  MOBILE_HOME_CODE,
  YES_OPTION,
  TRUE_OPTION,
  NO_OPTION,
  FALSE_OPTION,
} from '../Config/ApplicationConfig';

import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';
import DATA_STRUCTURE from '../Config/DataStructureConfig';
import { setApplication } from '../Redux/PersistRedux';
import Footer from '../Components/Footer';
import FormGroupText from '../Components/FormGroupText';
import FormGroupCheckbox from '../Components/FormGroupCheckbox';

const BASE = DATA_STRUCTURE.lien_holders;
const BASE_DAMAGED_ADDRESS = DATA_STRUCTURE.damagedAddress;

const DOCUMENT_TYPE = DOCUMENT_TYPES.lienDocument.code;

export const EnvironmentalInformationScreenComplete = (
  application,
  documents,
) => {
  return application.isGasConnectedAndInUseCode !== undefined;
};

class EnvironmentalInformationScreen extends Component {
  state = {
    currentScreen: null,
    editingIndex: null,
  };

  creatingLienHolder = false;

  deletingHouseholdMember = false;

  componentDidMount() {
    // this.props.loadDocuments(DOCUMENT_TYPE);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      data,
      errorText,
      fetching,
      application,
      initilized,
      onComplete,
      currentForm,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history,
    } = nextProps;

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        // history.push('/LienOnPropertyScreen');
        history.push('/TenantsScreen');
      }
    }
  }

  render() {
    const {
      application,
      initilized,
      fetching,
      history,
      options,
      submitForm,
      setFieldValue,
      values,
    } = this.props;

    const { editingIndex, currentScreen, errorText } = this.state;

    // console.log('values', JSON.stringify(values, null, 2));

    const disabled = false;

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    return (
      <View style={styles.mainContainer}>
        <ScrollView
          ref={(scroll) => {
            this._scroll = scroll;
          }}
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id={`EnvironmentalInformation.title`}
                  style={Fonts.style.title}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {errorView}

                <FormGroupRadio
                  field="isGasConnectedAndInUseCode"
                  labelId={`EnvironmentalInformation.isGasConnectedAndInUseCode`}
                  optionsKey="yes_no_unknown"
                  required
                  validate={isRequired}
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field="areOdorsNoticeableCode"
                  labelId={`EnvironmentalInformation.areOdorsNoticeableCode`}
                  optionsKey="yes_no"
                  required
                  validate={isRequired}
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field="isSitePreparedForEnvInspectionCode"
                  labelId={`EnvironmentalInformation.isSitePreparedForEnvInspectionCode`}
                  optionsKey="yes_no"
                  required
                  validate={isRequired}
                  disabled={disabled}
                />
              </Col>
            </Row>
            {values.isSitePreparedForEnvInspectionCode === NO_OPTION && (
              <Row>
                <Col sm={12}>
                  <FormGroupRadio
                    field="isApplicantAbleToPrepareSiteForEnvInspectionCode"
                    labelId={`EnvironmentalInformation.isApplicantAbleToPrepareSiteForEnvInspectionCode`}
                    optionsKey="yes_no"
                    required
                    validate={isRequired}
                    disabled={disabled}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field="siteHistoryCode"
                  labelId={`EnvironmentalInformation.siteHistoryCode`}
                  optionsKey="site_history"
                  required
                  validate={isRequired}
                  disabled={disabled}
                />
              </Col>
            </Row>

            {values.siteHistoryCode === '4' && (
              <Row>
                <Col sm={12}>
                  <FormGroupText
                    field={`siteHistoryOtherText`}
                    labelId="EnvironmentalInformation.siteHistoryOtherText"
                    placeholderId="EnvironmentalInformation.siteHistoryOtherText"
                    required
                    validate={isRequired}
                    disabled={disabled}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={12}>
                <FormattedMessage
                  id={'EnvironmentalInformation.siteHistoryInclude'}
                  style={Fonts.style.subtitle}
                />
                <FormGroupCheckbox
                  labelId="EnvironmentalInformation.siteIncludeSepticTank"
                  field={`siteIncludeSepticTank`}
                />
                <FormGroupCheckbox
                  labelId="EnvironmentalInformation.siteIncludeWaterWell"
                  field={`siteIncludeWaterWell`}
                />
                <FormGroupCheckbox
                  labelId="EnvironmentalInformation.siteIncludeUndergroundStorageTank"
                  field={`siteIncludeUndergroundStorageTank`}
                />
                <FormGroupCheckbox
                  labelId="EnvironmentalInformation.siteIncludeOther"
                  field={`siteIncludeOther`}
                />
                <FormGroupCheckbox
                  labelId="EnvironmentalInformation.siteIncludeUnknown"
                  field={`siteIncludeUnknown`}
                />
                <FormGroupCheckbox
                  labelId="EnvironmentalInformation.siteIncludeNone"
                  field={`siteIncludeNone`}
                  onPress={(val) => {
                    console.log(values.siteIncludeNone);
                    if (values.siteIncludeNone !== TRUE_OPTION) {
                      setFieldValue('siteIncludeUnknown', FALSE_OPTION);
                      setFieldValue('siteIncludeOther', FALSE_OPTION);
                      setFieldValue(
                        'siteIncludeUndergroundStorageTank',
                        FALSE_OPTION,
                      );
                      setFieldValue('siteIncludeWaterWell', FALSE_OPTION);
                      setFieldValue('siteIncludeSepticTank', FALSE_OPTION);
                    }
                  }}
                />
                {values.siteIncludeOther === TRUE_OPTION && (
                  <Row>
                    <Col sm={12}>
                      <FormGroupText
                        field={`siteIncludeOtherText`}
                        labelId="EnvironmentalInformation.siteIncludeOtherText"
                        placeholderId="EnvironmentalInformation.siteIncludeOtherText"
                        required
                        validate={isRequired}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            {/* "siteHistoryOtherText": "Other",
    "siteHistoryInclude": "Did the site historically include any of the following?",
    "siteIncludeSepticTank": "Septic Tank",
    "siteIncludeWaterWell": "Water Well",
    "siteIncludeUndergroundStorageTank": "Underground Storage Tank",
    "siteIncludeOther": "Other",
    "siteIncludeOtherText": "Other",
    "siteIncludeUnknown": "Unknown",
    "siteIncludeNone": "None of these" */}

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  onPress={submitForm}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </ScrollView>
      </View>
    );
  }
}

EnvironmentalInformationScreen = withFormik({
  mapPropsToValues: (props) => {
    const initialApplication = JSON.parse(JSON.stringify(props.application));
    // console.log(
    //   'mapPropsToValues',
    //   JSON.stringify(initialApplication, null, 2)
    // );
    return initialApplication;
  },
  isInitialValid: (props) =>
    props.application[BASE] && props.application[BASE].length > 0,
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { updateApplication } = props;

    if (
      values.siteIncludeNone !== TRUE_OPTION &&
      values.siteIncludeUnknown !== TRUE_OPTION &&
      values.siteIncludeOther !== TRUE_OPTION &&
      values.siteIncludeUndergroundStorageTank !== TRUE_OPTION &&
      values.siteIncludeWaterWell !== TRUE_OPTION &&
      values.siteIncludeSepticTank !== TRUE_OPTION
    ) {
      // Nothing selected
      values.siteIncludeNone = TRUE_OPTION;
    }
    if (values.siteIncludeOther !== TRUE_OPTION) {
      values.siteIncludeOtherText = '';
    }
    console.log('updatingApplication', values);
    updateApplication(values);
    setStatus('updatingApplication');
  },
})(EnvironmentalInformationScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { options, application, account, token, tokenId },
  document,
}) => ({
  options,
  account,
  token,
  tokenId,
  errorText: error,
  fetching,
  data,
  initilized,
  application,
  documents: document.documents,
  documentsError: document.error,
  documentsFetching: document.fetching,
});

const mapDispatchToProps = (dispatch) => ({
  loadDocuments: (docType) =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  // deleteHouseholdMember: memberId =>
  //   dispatch(ApplicationActions.ApplicationHouseholdMemberDelete(memberId)),
  setApplication: (application) =>
    dispatch(ApplicationActions.ApplicationSuccess(application)),
  updateApplication: (application) =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
  deleteRecord: (memberId, key) =>
    dispatch(ApplicationActions.ApplicationDeleteRecord(BASE, memberId, key)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnvironmentalInformationScreen);
